import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ngxCsv } from 'ngx-csv/ngx-csv';

import { AuthService } from '../../../../components/auth/auth.service';
import { ImpersonationService } from '../../../../components/auth/impersonation.service';

import { DarkPoolService } from '../dark-pool.service';

import { cloneDeep, filter } from 'lodash';
import * as d3 from 'd3';

@Component({
  selector: 'dark-pool-email-scanners',
  template: require('./dark-pool-email-scanners.html')
})
export class DarkPoolEmailScanners implements OnInit {
  @ViewChild('domainTmpl', { static: true }) domainTmpl: TemplateRef<any>;
  @ViewChild('exportTmpl', { static: true }) exportTmpl: TemplateRef<any>;
  @ViewChild('humanActivityHeaderTmpl', { static: true }) humanActivityHeaderTmpl: TemplateRef<any>;
  @ViewChild('integerTmpl', { static: true }) integerTmpl: TemplateRef<any>;
  @ViewChild('percentTmpl', { static: true }) percentTmpl: TemplateRef<any>;

  @Input() currentOrg;
  @Input() visible;

  static parameters = [BsModalService, AuthService, DarkPoolService, ImpersonationService];
  constructor(modalService: BsModalService, authService: AuthService, darkPoolService: DarkPoolService, impersonation: ImpersonationService) {
    this.modalService = modalService;
    this.authService = authService;
    this.darkPoolService = darkPoolService;
    this.impersonation = impersonation;

    this.isDemo = false;

    this.rows = [];
    this.loading = true;
    this.contactCount = null;
    this.filterType = 'both';
    this.searchString = '';
    this.exporting = false;
    this.selectedScanners = [];
    this.page = {
      number: 0,
      numberPlusOne: 1,
      size: 15,
      sizes: [10, 15, 25, 50, 100, 250]
    };
  }

  ngOnInit() {
    this.isDemo = this.impersonation.isDemoMode();
    this.authService.getCurrentUser().then(user => {
      this.currentUser = user;
    });

    //Column defs for the scanners grid
    this.columns = [
      {
        prop: 'selected',
        name: '',
        sortable: false,
        canAutoResize: false,
        draggable: false,
        resizable: false,
        headerCheckboxable: true,
        checkboxable: true,
        width: 40
      },
      { prop: 'domain', name: 'Domain', cellTemplate: this.domainTmpl },
      { prop: 'totalContacts', name: 'Total Contacts', cellClass: 'rightAlign', headerClass: 'rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'scanners', name: 'Contacts with possible Scanner', cellClass: 'rightAlign', headerClass: ' darkPoolHeaderAttention rightAlign', cellTemplate: this.integerTmpl },
      { prop: 'scannersPercent', name: 'Scanner Percent', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl },
      { prop: 'externalActivityPercent', name: 'Percent with Human Activity', cellClass: 'rightAlign', headerClass: 'darkPoolHeaderAttention rightAlign', cellTemplate: this.percentTmpl, headerTemplate: this.humanActivityHeaderTmpl },
      { prop: 'domain', name: 'Export Contacts<br/>with possible Scanner', headerClass: 'darkPoolHeaderAttention', cellTemplate: this.exportTmpl, sortable: false }
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    for(const propName in changes) {
      if(propName === 'visible') {
        //Lazy load.
        if(this.visible) {
          if(this.isDemo) {
            this.loadDemoData();
            return;
          }

          this.loadData();
        }
      }
    }
  }

  onPageChange(event) {
    this.page.number = event.offset;
    this.page.numberPlusOne = this.page.number + 1;
    this.onPageSizeChange();
  }

  onPageSizeChange() {
    this.page.totalPages = Math.ceil(this.rows.length / this.page.size);
  }

  onPageNumberChange() {
    this.page.number = this.page.numberPlusOne - 1;
  }

  loadData() {
    this.rows = [];
    this.selectedScanners = [];
    this.page.number = 0;
    this.page.numberPlusOne = 1;

    //TODO: Where to get the periodIndex?
    this.loading = true;
    this.darkPoolService.getScanners(0).then(scanners => {
      this.rows = scanners;
      this.rowsCopy = cloneDeep(scanners);
      this.countContacts();
      this.getOnlyScannerActivityCount();
      this.loading = false;
    })
      .catch(e => {
        console.log('Exception retrieving scanners.', e);
        this.loading = false;
      });
  }

  countContacts() {
    //Count contacts
    this.contactCount = 0;
    this.rows.forEach(item => {
      this.contactCount += item.scanners;
    });
    this.onPageSizeChange();
  }

  getOnlyScannerActivityCount() {
    this.darkPoolService.getOnlyScannerActivityCount(0).then(count => {
      this.onlyScannerActivityCount = count;
    });
  }

  filterChanged() {
    this.rows = [];

    //First, filter by type (b2b/b2c)
    if(this.filterType === 'both') {
      this.rows = cloneDeep(this.rowsCopy);
    }
    else {
      this.rows = filter(this.rowsCopy, o => o.type === this.filterType);
    }

    //Then, filter by search string
    var searchString = this.searchString.toLowerCase();
    if(searchString !== '') {
      function searchFilter(row) {
        return row.domain && row.domain.toLowerCase().indexOf(searchString) > -1;
      }
      this.rows = this.rows.filter(searchFilter);
    }

    this.countContacts();
  } //end: filterChanged()

  exportContactIDs(domain) {
    this.exporting = true;
    this.darkPoolService.exportScannerContactIDs(0, domain).then(results => {
      var headers = ['Eloqua Contact ID', 'Human Activity'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Scanner contacts-${domain}`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(results, filename, options);
      this.exporting = false;
    });

    return false;
  }

  onSelect({ selected }) {
    this.selectedScanners.splice(0, this.selectedScanners.length);
    this.selectedScanners.push(...selected);
  }

  exportSelectedContactIDs() {
    this.exporting = true;
    var domains = this.selectedScanners.map(x => x.domain);

    this.darkPoolService.exportDomainsScannerContactIDs(0, domains).then(results => {
      var headers = ['Eloqua Contact ID', 'Human Activity'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = `Scanner contacts-for ${d3.format(',')(domains.length)} domains`;

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(results, filename, options);
      this.exporting = false;
    });

    return false;
  }

  exportAllContactIDs() {
    this.exporting = true;
    this.darkPoolService.exportAllScannerContactIDs(0).then(results => {
      var headers = ['Eloqua Contact ID', 'Human Activity'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'All Scanner contacts';

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(results, filename, options);
      this.exporting = false;
    });

    return false;
  }

  exportOnlyScannerActivityContactIDs() {
    this.exporting = true;
    this.exportingOnlyScannerActivityContacts = true;
    this.darkPoolService.exportOnlyScannerActivityContactIDs(0).then(results => {
      var headers = ['Eloqua Contact ID', 'Sends Count'];
      var options = {
        fieldSeparator: ',',
        showLabels: true,
        noDownload: false,
        headers
      };

      var filename = 'Contacts with 100% Scanner Activity';

      if(this.currentOrg.darkPoolSetting === 'freemium') {
        filename += '-FREEMIUM';
      }

      new ngxCsv(results, filename, options);
      this.exporting = false;
      this.exportingOnlyScannerActivityContacts = false;
    });

    return false;
  }

  loadDemoData() {
    this.selectedScanners = [];
    this.loading = true;
    this.darkPoolService.getDemoData('scanners').then(scanners => {
      this.rows = scanners;
      this.rowsCopy = cloneDeep(scanners);
      this.countContacts();
      this.loading = false;
    });
  }
}
